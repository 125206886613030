import { create } from 'superstruct';

import { EBM_BACKEND_URL, EBM_BACKEND_URL_FALLBACK } from '$env';
import { fetchSiteInfo } from './api/calls/siteInfo';
import { ConfigValidator } from './api/validators/Config';
import { SiteInfoValidator } from './api/validators/SiteInfo';
import log from './log';

function hydrateConfig(remoteConfig: ConfigDto, backendUrl: string): Config {
  const combinedConfig: ConfigDto = {
    backendUrl,
    ...remoteConfig,
    ...(backendUrl !== EBM_BACKEND_URL_FALLBACK && {
      fileBackendUrl: backendUrl,
    }),
  };

  // make css and logo urls absolute
  if (/^https?:\/\//.exec(combinedConfig.cssUrl) === null) {
    combinedConfig.cssUrl = backendUrl + combinedConfig.cssUrl;
  }
  if (
    combinedConfig.cssPatcherUrl !== undefined &&
    combinedConfig.cssPatcherUrl !== '' &&
    /^https?:\/\//.exec(combinedConfig.cssPatcherUrl) === null
  ) {
    combinedConfig.cssPatcherUrl = backendUrl + combinedConfig.cssPatcherUrl;
  }
  if (/^https?:\/\//.exec(combinedConfig.logoUrl) === null) {
    combinedConfig.logoUrl = backendUrl + combinedConfig.logoUrl;
  }

  // validate
  try {
    const config = create(combinedConfig, ConfigValidator);
    return config as Config;
  } catch (e) {
    log('Config is invalid!', e, 'error');
    // still return
    return combinedConfig as Config;
  }
}

export async function getSiteInfo(): Promise<SiteInfoDto> {
  const siteInfo = await fetchSiteInfo(EBM_BACKEND_URL);

  siteInfo.configs = hydrateConfig(siteInfo.configs, EBM_BACKEND_URL);

  try {
    const siteInfoWithDefaults = create(siteInfo, SiteInfoValidator);
    return siteInfoWithDefaults;
  } catch (error) {
    log('SiteInfo response is invalid!', { error, siteInfo }, 'error');
    log('Continue anyway...');
  }

  return siteInfo;
}
